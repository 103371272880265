import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
  CCard,
  LContact,
  CDefinition,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: (
              <>
                Nursing Room /<br className="u_sp" /> Diaper
                <br className="u_sp" /> Changing
                <br className="u_sp" /> Corner
              </>
            ),
            sub: '授乳室・おむつ替えコーナー',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/nursing_room/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/nursing_room/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '館内施設・サービス',
                path: '/facilities/',
              },
            ],
            current: {
              label: '授乳室・おむつ替えコーナー',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CCard
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/facilities/nursing_room/img_nursing_room.png',
                  alt: '',
                },
                content: (
                  <>
                    <h2 className="c_headingLv4 u_mb25 u_colorGold">授乳室</h2>
                    <CDefinition
                      exClass="u_mb20"
                      data={[
                        {
                          title: 'ホテル棟',
                          text: <>1F（おむつ交換台もございます。）</>,
                        },
                        {
                          title: '宴会棟',
                          text: <>2F　3F</>,
                        },
                      ]}
                    />
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/facilities/nursing_room/img_nursing_room02.png',
                  alt: '',
                },
                content: (
                  <>
                    <h2 className="c_headingLv4 u_mb25 u_colorGold">
                      おむつ替えコーナー
                    </h2>
                    <CDefinition
                      exClass="u_mb20"
                      data={[
                        {
                          title: 'ホテル棟',
                          text: (
                            <>
                              1F（授乳室内）
                              <br />
                              1F　68F　70F（女性化粧室内）
                            </>
                          ),
                        },
                        {
                          title: '宴会棟',
                          text: <>B1F　1F　3F（女性化粧室内）</>,
                        },
                      ]}
                    />
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
